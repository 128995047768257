import ApiEntity from './ApiEntity';

export default class Client extends ApiEntity {
  id;
  name;
  default = false;
  email;
  mapping;
  policy = 1;
  siret;
  activityDuration;
  scan_for_load = false;
  scan_for_prepare = false;
  scan_for_deliver = false;
  signature_proof = false;
  photo_proof = false;
  mandatory_proof = false;
  disable_item_reasons = false;
  auto_state = false;
  disable_activity_reasons = false;
  distance_geoloc = 200;
  survey_enabled = false;
  company_name;
  address;
  hotline_email;
  hotline_phone;
  client_data_officer_email;
  ftp_host;
  ftp_port;
  ftp_password;
  ftp_username;
  ftp_path;
  ftp_ssl = false;
  ftp_ssl_implicit = false;
  ftp_ftps = false;
  api_key;
  import_format = 'api';
  report_format;
  report_type = 'api';
  customImport = false;
  lambdaName;
  workflow;
  importerLimit = 300;
  constructor(o) {
    super();
    this.fill(o);
  }

  static create(data) {
    return Client.createInstance(data);
  }
}
